//@ts-nocheck
import React from "react";
import { withStyles, Grid, createStyles, Button, Box, Modal, Paper, Typography } from "@material-ui/core";
import StorageProvider from "../../../../framework/src/StorageProvider.web";
import { withRouter } from "react-router";
import withAlertBox from "../../../../components/src/withAlertBox.Web";
import withToast from "../../../../components/src/withSnackBar.Web";
import withLoader from "../../../../components/src/withLoader.Web";
import withDialogBox from "../../../../components/src/withDialog.web";
import DocViewer from "@cyntler/react-doc-viewer";
import SignaturePad from "react-signature-canvas";
import GetAppIcon from "@material-ui/icons/GetApp";

const MyCustomPNGRenderer: any = ({ mainState: { currentDocument } }) => {
  if (!currentDocument) return null;
  return (
    <div style={{ width: "100%", height: 500 }} id="my-png-renderer">
      <iframe
        style={{ width: "100%", height: 500 }}
        src={`https://docs.google.com/viewer?url=${currentDocument.uri}&embedded=true`}
      />
    </div>
  );
};
MyCustomPNGRenderer.fileTypes = ["doc", "docx"];
MyCustomPNGRenderer.weight = 1;

export class ViewDocumentDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      token: null,
      openSignaturePad: false,
      signatureData: null,
      imageURL: null,
      info: props.dataToPass.info || false,
      isDataAvailable: props.dataToPass.isDataAvailable || false
    };
    this.sigCanvas = React.createRef();
  }
  async componentDidMount() {
    const token = await StorageProvider.get("authToken");
    if (token) {
      this.setState({ token });
    }
  }


  handleSubmit2 = () => {
    this.setState({ openSignaturePad: true });
  };

  saveSignature = () => {
    if (!this.sigCanvas.current.isEmpty()) {
      const signatureData = this.sigCanvas.current.getTrimmedCanvas().toDataURL("image/png");
      if (this.props.dataToPass.onCheckboxSubmit) {
        this.props.dataToPass.onCheckboxSubmit(
          true,
          signatureData,
          this.props.dataToPass.survey_id,
          this.props.dataToPass.document_template_id,
        );
      }
      this.setState({ openSignaturePad: false });
      this.props.onCancel();
    }
    else {
      this.setState({ openSignaturePad: false });
      if (this.props.dataToPass.onCheckboxSubmit) {
        this.props.dataToPass.onCheckboxSubmit(
          false,
          this.props.dataToPass.survey_id,
          this.props.dataToPass.document_template_id,
        );
      }
    }
  };

  clearSignature = () => {
    this.sigCanvas.current.clear();
  };
  open = () => {
    window.open(
      this.props.dataToPass?.documentItem?.attributes?.document,
      "_blank",
      "noopener"
    );
  };

  render() {
    const { classes, dataToPass } = this.props;
    let contentToRender;
    let docs = [];

    if (!this.state.info && dataToPass.documentItem) {
      docs = [
        {
          uri: dataToPass.btnshow ? dataToPass?.documentItem : dataToPass?.documentItem.attributes.document,
          fileType: "doc",
        },
      ];

      if (dataToPass.view) {
        contentToRender = null;
      } else if (dataToPass.btnshow) {
        contentToRender = (
          <>
            <Box display="flex" justifyContent="center" alignItems="center" width="100%" sx={{
              gap: "5%"
            }}>
              <Button
                component="a"
                href={this.props.dataToPass.documentItem}
                target="_blank"
                rel="noopener noreferrer"
                download
                color="primary"
                style={{
                  width: "25%",
                  marginTop: "1.5rem",
                  textDecoration: "none",

                }}
                startIcon={<GetAppIcon />}
              >
                Download PDF
              </Button>
              <Button
                variant="contained"
                color="secondary"
                style={{
                  width: "25%",
                  marginTop: "1.5rem",
                }}
                onClick={this.props.onCancel}
              >
                Reject
              </Button>
              <Button
                variant="contained"
                color="primary"
                style={{
                  width: "25%",
                  marginTop: "1.5rem",
                }}
                onClick={this.handleSubmit2}
              >
                Accept
              </Button>
            </Box>
          </>
        );
      } else {
        contentToRender = (
          <Button
            variant="contained"
            color="primary"
            onClick={this.open.bind(this)}
            style={{
              width: "25%",
              marginTop: "1.5rem",
            }}
          >
            Download
          </Button>
        );
      }
    }
    if (this.state.info) {
      return (
        <>
          <Typography variant="h3" className={classes.title}>
            Client Information
          </Typography>
          {this.state.isDataAvailable ? (
            <Paper className={classes.tableContainer}>
              <Grid item xs={12}>
                <Typography>
                  <span className={classes.tableCellLabel}>Email:</span>
                  <span className={classes.tableCellValue}>{this.props.dataToPass.clientInfo.client_email}</span>
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography>
                  <span className={classes.tableCellLabel}>Full name:</span>
                  <span className={classes.tableCellValue}>{this.props.dataToPass?.clientInfo.client_full_name}</span>
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography>
                  <span className={classes.tableCellLabel}>Date:</span>
                  <span className={classes.tableCellValue}> {new Date(this.props.dataToPass.clientInfo.signing_time).toLocaleString()}</span>
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography>
                  <span className={classes.tableCellLabel}>Browser:</span>
                  <span className={classes.tableCellValue}>
                    {this.props.dataToPass.clientInfo.browser_details} <br />
                    IP: {this.props.dataToPass.clientInfo.ip_address}
                  </span>
                </Typography>
              </Grid>

              <Box display="flex" justifyContent="center">

                <Grid item xs={12}>
                  <Typography className={classes.tableCellLabel}> Client Signature : </Typography>
                  <div className={classes.signatureBox}>
                    <img src={this.props.dataToPass.clientInfo.signature}
                      alt="Client Signature" style={{ width: "100%", height: "100%" }} />
                  </div>
                </Grid>
              </Box>
              <Grid item xs={12} justifyContent="center" style={
                {
                  display: "flex",
                  marginTop: "30px"
                }}>
                <Button variant="contained"
                  color="primary"
                  onClick={this.props.onCancel}>
                  Close
                </Button>
              </Grid>
            </Paper>
          ) : (
            <Grid container justifyContent="center" className={classes.rootContainer}>
              <Typography variant="h6" color="textSecondary" align="center">
                Document signature is pending
              </Typography>
              <Grid item xs={12} justifyContent="center" style={
                {
                  display: "flex",
                  marginTop: "30px"
                }}>
                <Button variant="contained"
                  color="primary"
                  onClick={this.props.onCancel}>
                  Close
                </Button>
              </Grid>
            </Grid>
          )}
        </>
      );
    }

    return (
      <Grid container justifyContent="center" className={classes.rootContainer}>
        <Grid item xs={12}>

          <DocViewer
            documents={docs}
            prefetchMethod="GET"
            config={{
              header: { disableHeader: true },
            }}
            pluginRenderers={[MyCustomPNGRenderer]}
            style={{ width: "100%", height: 500 }}
          />
        </Grid>
        {contentToRender}
        <Modal
          open={this.state.openSignaturePad}
          onClose={() => this.setState({ openSignaturePad: false })}
          aria-labelledby="signature-modal"
          aria-describedby="signature-pad"
        >
          <div className={classes.modalContainer}>
            <h2 id="signature-modal">Sign Document</h2>
            <SignaturePad
              ref={this.sigCanvas}
              canvasProps={{ className: "signatureCanvas" }}
              backgroundColor="#f4f4f4"
              style={{ border: "1px solid #000" }}
            />
            <Box>
              <Button
                variant="contained"
                color="primary"
                onClick={this.saveSignature}
                style={{ margin: "10px", textTransform: "none" }}
              >
                Accept and Sign
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={this.clearSignature}
                style={{ margin: "10px" }}
              >
                Clear
              </Button>
            </Box>
          </div>
        </Modal>
      </Grid>
    );
  }
}

const ViewDocumentDialogWithRouter = withRouter(ViewDocumentDialog);
const ViewDocumentDialogWithToast = withToast(ViewDocumentDialogWithRouter);
const ViewDocumentDialogWithLoader = withLoader(ViewDocumentDialogWithToast);
const ViewDocumentDialogWithAlertBox = withAlertBox(
  ViewDocumentDialogWithLoader
);
const ViewDocumentDialogWithDialogBox = withDialogBox(
  ViewDocumentDialogWithAlertBox
);


export default withStyles((theme) =>
  createStyles({
    rootContainer: {
      width: "60vw",
      paddingBottom: "2rem",
      marginTop: "2.5rem",
    },
    modalContainer: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "#fff",
      padding: "20px",
      outline: "none",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    signatureCanvas: {
      width: "700px",
      height: "400px",
      border: "1px solid #000",
    },


    tableContainer: {
      padding: theme.spacing(3),
      backgroundColor: "#f9f9f9",
      borderRadius: "10px",
      marginBottom: theme.spacing(5),

    },
    table: {
      width: "100%",
      borderCollapse: "collapse",
      fontSize: "17px"
    },
    tableRow: {
      borderBottom: "1px solid #e0e0e0",
    },
    tableCellLabel: {
      padding: theme.spacing(1),
      fontWeight: "bold",
      textAlign: "left",
      fontSize: "14px"
    },
    tableCellValue: {
      padding: theme.spacing(1),
      textAlign: "right",
      wordBreak: "break-word",
      fontSize: "14px"
    },
    signatureContainer: {
      display: "flex",
      justifyContent: "space-between",
      marginTop: theme.spacing(2),
    },
    signatureBox: {
      border: "1px solid #ccc",
      padding: theme.spacing(1),
      width: "150px",
      height: "100px",
    },
    title: {
      marginBottom: "1rem",
      textAlign: "center",
      fontWeight: "bold",
      fontSize: "20px"
    },
  })
)(ViewDocumentDialogWithDialogBox);
