//@ts-nocheck
// Customizable Area Start
import React from "react";
import OnBoardingSurveyLandingPageController, {
  Props,
} from "./OnBoardingSurveyLandingPageController.web";
import {
  withStyles,
  Grid,
  Typography,
  createStyles,
  Button,
  AppBar,
  Toolbar,
  Box,
  Divider,
  IconButton,
  makeStyles,
  TextField,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormGroup,
  Checkbox,
  MenuItem,
  ThemeProvider,
} from "@material-ui/core";
import clsx from "clsx";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import InsertDriveFileOutlinedIcon from "@material-ui/icons/InsertDriveFileOutlined";
import ListItemText from "@material-ui/core/ListItemText";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { CloseOutlined } from "@material-ui/icons";
import { withRouter } from "react-router";
import withAlertBox from "../../../../components/src/withAlertBox.Web";
import withToast from "../../../../components/src/withSnackBar.Web";
import withLoader from "../../../../components/src/withLoader.Web";
import withDialog from "../../../../components/src/withDialog.web";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import StepConnector from "@material-ui/core/StepConnector";
import Slider from "@material-ui/core/Slider";
import PotentialSavings from "../PotentialSavings.web";
import LinearProgress from "@material-ui/core/LinearProgress";
import * as DOMPurify from "dompurify";
import { client } from "../../../../components/src/HelloSignComponent.web";
import { createTheme } from "@material-ui/core/styles";
import PaymentPage from "../CPAPayment.web";
import ViewDocumentDialogWeb from "../Settings/ViewDocumentDialog.web";
import ConfirmEmailDialog from "../roadmap/RoadmapConfirmEmailDialog.web"


const formatCurrencyObs = new Intl.NumberFormat("en-US", {
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
  roundingMode: "ceil",
});
const ColorlibConnector = withStyles({
  active: {
    "& $line": {
      backgroundColor: localStorage.getItem("themePrimaryColor") || "#54A4A6",
    },
  },
  completed: {
    "& $line": {
      backgroundColor: localStorage.getItem("themePrimaryColor") || "#54A4A6",
    },
  },
  vertical: {
    padding: "0px",
    marginLeft: "9px",
  },
  line: {
    width: 1,
    border: 0,
    backgroundColor: "#eaeaf0",
    borderRadius: 1,
  },
})(StepConnector);

const ColorStepLabel = withStyles((theme) => ({
  root: {
    cursor: "pointer",
    color: "#000",
    padding: "0px",
  },
  active: {
    postition: "relative",
  },
  label: {
    cursor: "pointer",
    fontSize: "0.9rem",
  },
  iconContainer: {
    borderRadius: theme.spacing(0.5, 0, 0, 0.5),
    padding: theme.spacing(0, 0.5),
  },
}))(StepLabel);
const useColorlibStepIconStyles = makeStyles({
  root: {
    cursor: "pointer",
    backgroundColor: "#fff",
    border: "1px solid #d3d3d3",
    zIndex: 1,
    width: 12,
    height: 12,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  active: {
    backgroundColor: localStorage.getItem("themePrimaryColor") || "#54A4A6",
    border: `1px solid ${localStorage.getItem("themePrimaryColor") ||
      "#54A4A6"}`,
  },
  completed: {
    backgroundColor: localStorage.getItem("themePrimaryColor") || "#54A4A6",
    border: `1px solid ${localStorage.getItem("themePrimaryColor") ||
      "#54A4A6"}`,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
});

function ColorlibStepIcon(props: StepIconProps) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    ></div>
  );
}

const ColorStep = withStyles((theme) => {
  return {
    root: {
      borderRadius: "4px",
    },
  };
})(Step);
const iOSBoxShadow =
  "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)";

const CompletetionPercentageSlider = withStyles((theme) => {
  return {
    root: {
      color: theme?.palette?.primary.main,
      height: 2,
      padding: "15px 0",
    },
    thumb: {
      height: 16,
      width: 16,
      backgroundColor: "#fff",
      boxShadow: iOSBoxShadow,
      marginTop: -7,
      marginLeft: -14,
      "&:focus, &:hover, &$active": {
        boxShadow:
          "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)",
        // Reset on touch devices, it doesn't add specificity
        "@media (hover: none)": {
          boxShadow: iOSBoxShadow,
        },
      },
    },
    active: {},
    valueLabel: {
      left: "calc(-50% - 0)",
      top: -16,
      "& *": {
        background: "transparent",
        color: "#000",
      },
    },
    track: {
      height: 2,
    },
    rail: {
      height: 2,
      opacity: 0.5,
      backgroundColor: theme?.palette?.primary.main,
    },
    mark: {
      backgroundColor: "#bfbfbf",
      height: 8,
      width: 1,
      marginTop: -3,
    },
    markActive: {
      opacity: 1,
      backgroundColor: "currentColor",
    },
  };
})(Slider);
const NoPackage = () => {
  return (
    <Grid item xs={12} container justifyContent="center">
      <Grid xs={10} item>
        <Typography variant="h3" color="textSecondary" align="center">
          No packages available
        </Typography>
      </Grid>
    </Grid>
  );
};
const PotentialSavingsNew = ({ m, classes, ...props }) => {
  return !m?.pkg?.attributes?.name?.includes("Reporting") ? (
    <Grid item xs={12} container alignItems="flex-end" justifyContent="center">
      {/* <Box className={classes.bottomDivider} /> */}
      <Typography
        color="primary"
        align="center"
        className={classes.cardPackageBottomTextpt}
        onClick={() => {
          props.openPotentialSavings();
        }}
      >
        See Potential Savings = $36,500
      </Typography>
    </Grid>
  ) : null;
};
const renderRoadmapPackages = (
  packageList,
  selectedPackage,
  setSelectedPackage,
  classes,
  props
) => {
  return packageList?.length > 0 ? (
    packageList?.map((m) => {
      return (
        <Grid
          key={m.paymentTermKey + "_" + m?.pkg?.id}
          item
          xs={12}
          md={4}
          className={
            selectedPackage?.pkg?.id &&
              selectedPackage?.pkg?.id === m?.pkg?.id &&
              selectedPackage?.paymentTermKey == m?.paymentTermKey
              ? classes.cardPackageSelectedObs
              : classes.cardPackageObs
          }
          onClick={(event) => {
            setSelectedPackage(m);
          }}
        >
          <Grid container>
            <Grid item xs={12}>
              <Typography
                className={classes.optionTitle}
                color="textSecondary"
                align="center"
              >
                {m?.pkg?.attributes?.name}
              </Typography>
              <Typography
                className={classes.optionPriceSmall}
                color="primary"
                align="center"
              >
                {m.value}
              </Typography>
              <Divider />
              <div className={classes.featureListContainer}>
                {m?.pkg?.attributes?.features?.data?.map((feature, index) => {
                  return (
                    <Typography
                      key={index}
                      color="textSecondary"
                      className={classes.featureTextOBS}
                    >
                      {feature?.attributes?.feature_name}
                    </Typography>
                  );
                })}
              </div>
            </Grid>
            <PotentialSavingsNew {...props} m={m} />
          </Grid>
        </Grid>
      );
    })
  ) : (
    <NoPackage />
  );
};
const renderSurveyPackages = (
  packageList,
  selectedPackage,
  setSelectedPackage,
  classes,
  props
) => {
  return packageList?.length > 0 ? (
    packageList?.map((m) => {
      return (
        <Grid
          key={m.id}
          item
          xs={12}
          md={4}
          className={
            selectedPackage && selectedPackage?.id == m.id
              ? classes.cardPackageSelectedObs
              : classes.cardPackageObs
          }
          onClick={(event) => {
            setSelectedPackage(m);
          }}
        >
          <Grid container>
            <Grid item xs={12}>
              <Typography
                className={classes.optionTitle}
                color="textSecondary"
                align="center"
              >
                {m.name}
              </Typography>
              <Typography
                className={classes.optionPriceLarge}
                color="primary"
                align="center"
              >
                $ {formatCurrencyObs.format(m.price)}
                <Typography
                  className={classes.optionPriceSmall}
                  color="primary"
                  align="center"
                  display="inline"
                >
                  Upfront
                </Typography>
              </Typography>
              <Divider />
              <div className={classes.featureListContainer}>
                {m?.feature_name_list?.map((feature, index) => {
                  return (
                    <Typography
                      key={index}
                      color="textSecondary"
                      className={classes.featureTextOBS}
                    >
                      {feature}
                    </Typography>
                  );
                })}
              </div>
            </Grid>
            <PotentialSavingsNew m={m} {...props} />
          </Grid>
        </Grid>
      );
    })
  ) : (
    <NoPackage />
  );
};
export const PackageList = (props) => {
  const {
    classes,
    packageList,
    selectedPackage,
    setSelectedPackage,
    setPackageAndMoveToNextStep,
    roadmap_id,
  } = props;

  return (
    <Grid
      container
      justifyContent="space-around"
      alignItems="start"
      className={classes.mainContainer}
    >
      <Grid
        xs={12}
        item
        style={{
          position: "relative",
        }}
      >
        <Grid container justifyContent="center">
          <Grid item xs={9}>
            <Typography
              className={classes.packagePageHeading}
              color="primary"
              align="center"
              gutterBottom
            >
              Here are the recommended packages
            </Typography>
          </Grid>
          <Grid item xs={12} md={9}>
            <Grid container justifyContent="center">
              {roadmap_id
                ? renderRoadmapPackages(
                  packageList,
                  selectedPackage,
                  setSelectedPackage,
                  classes,
                  props
                )
                : renderSurveyPackages(
                  packageList,
                  selectedPackage,
                  setSelectedPackage,
                  classes,
                  props
                )}
            </Grid>
          </Grid>
          <Grid item xs={12} md={10} container justifyContent="center">
            <Button
              color="primary"
              variant="contained"
              id="confirmbutton"
              onClick={() => {
                setPackageAndMoveToNextStep();
              }}
              disabled={selectedPackage === null}
            >
              Confirm your selection
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};


export const DocumentList = (props) => {
  const {
    classes,
    signatureRequests,
    submitSignatureRequestAndMoveToNextStep,
    openViewDocumentTemplateDialog
  } = props;
  const disableSubmit = signatureRequests?.every((sign) => {
    return sign.status;
  });

  return (
    <Grid container justifyContent="center" className={classes.mainContainer}>
      <Grid className={classes.documentListContainer} item xs={9}>
        <List
          classes={{
            root: classes.listContainer,
          }}
          dense={false}
        >
          {signatureRequests && signatureRequests.length > 0 ? (
            signatureRequests.map((value, key) => {
              return (
                <ListItem
                  key={key}
                  classes={{
                    root: classes.docListItem,
                  }}
                  onClick={() => {
                    openViewDocumentTemplateDialog(value.document, value.status, value.survey_id, value.document_template_id);
                  }}
                >
                  <ListItemText
                    classes={{
                      primary: classes.docListText,
                    }}
                    primary={value.document_template_title}
                  />
                  <ListItemSecondaryAction>
                    {!value.status ? (
                      <Typography
                        className={classes.documentOpenLink}
                        color="primary"
                        variant="body1"
                        onClick={() => {
                          openViewDocumentTemplateDialog(value.document, value.status, value.survey_id, value.document_template_id);
                        }}
                      >
                        Needs Review
                      </Typography>
                    ) : (
                      <IconButton edge="end" aria-label="delete">
                        <CheckCircleIcon
                          color="primary"
                          style={{ fontSize: "24px" }}
                        />
                      </IconButton>
                    )}
                  </ListItemSecondaryAction>
                </ListItem>
              );
            })
          ) : (
            <ListItem>
              <ListItemText
                classes={{
                  primary: classes.noItemAvailable,
                }}
                primary="No documents available for sign"
              ></ListItemText>
            </ListItem>
          )}
        </List>
      </Grid>
      <Grid item xs={10}>
        <Grid justifyContent="flex-end" container>
          <Grid item xs={2}>
            <Button
              onClick={() => {
                submitSignatureRequestAndMoveToNextStep();
              }}
              id="documentSubmit"
              disabled={!disableSubmit || props.signatureRequests?.length == 0}
              fullWidth
              variant="contained"
              color="primary"
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export const CheckListContainer = (props) => {
  const { classes, selectedPackage, availableCheckList, roadmap_id } = props;
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      className={classes.mainContainer}
    >
      <Grid item md={6} xs={12}>
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={10}>
            <Typography color="primary" className={classes.heading}>
              {roadmap_id
                ? selectedPackage?.pkg?.attributes?.name
                : selectedPackage?.attributes?.name}{" "}
              - Getting Started
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.subHeading}>
              Please submit the following information to complete your
              onboarding.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent="center" spacing={1}>
              {availableCheckList?.map((checkist) => {
                return (
                  <Grid
                    item
                    key={checkist.id}
                    style={{ textAlign: 'center' }}
                    xs={availableCheckList.length > 4
                      ? 3
                      : Math.round(12 / availableCheckList.length)}
                  >
                    <div className={classes.packageNameCard}>
                      {checkist.attributes.checklist_name}
                    </div>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
          <Grid item xs={10}>
            <Grid
              container
              style={{ marginTop: "24px" }}
              spacing={1}
              justifyContent="center"
            >
              <Grid item xs={3}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={props?.saveForLater}
                  className={classes.saveForLatter}
                  fullWidth
                >
                  Save for Later
                </Button>
              </Grid>
              <Grid item xs={3}>
                <Button
                  fullWidth
                  id="startchecklist"
                  onClick={() => {
                    props?.startOnBoardingSurveyForCheckList();
                  }}
                  variant="contained"
                  color="primary"
                >
                  Start
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export class OnBoardingSurveyLandingPage extends OnBoardingSurveyLandingPageController {
  fileInputRef = React.createRef<HTMLInputElement>();
  constructor(props: Props) {
    super(props);
  }

  async componentDidMount(): Promise<void> {
    const query = new URLSearchParams(this.props.location.search);
    const surveyId = query.get("surveyId");
    const queryParams = new URLSearchParams(atob(surveyId));
    const urlState = this.props.history.location.state;
    const client_id = urlState?.client_id || queryParams?.get("client_id");
    const survey_id = urlState?.survey_id || queryParams?.get("survey_id");
    const account_id = urlState?.account_id || queryParams?.get("account_id");
    const package_management_id =
      urlState?.package_management_id ||
      queryParams?.get("selected_package_id");
    const access_token = urlState?.access_token;
    const allow_to_change_package_id =
      urlState?.allow_to_change_package_id ||
      queryParams?.get("allow_to_change_package_id");
    const custom_package_id =
      urlState?.custom_package_id || queryParams?.get("custom_package_id");
    const roadmap_id = urlState?.roadmap_id || queryParams?.get("roadmap_id");
    const payment_term =
      urlState?.payment_term || queryParams?.get("payment_term");
    const parent_package_type =
      urlState?.parent_package_type || queryParams?.get("parent_package_type");

    if (client_id && (survey_id || roadmap_id) && account_id) {
      this.setState(
        {
          clientId: client_id,
          cfsSurveyId: survey_id,
          accountId: account_id,
          package_management_id: package_management_id,
          paymentaccesstoken: access_token,
          isLoadingPage: true,
          allow_to_change_package_id:
            allow_to_change_package_id == "false" ? false : true,
          custom_package_id: custom_package_id,
          roadmap_id: roadmap_id,
          payment_term: payment_term,
          parent_package_type: parent_package_type,
        },
        () => {
          this.getCPADetialbyAccessToken();
          this.getClientmail()
        },
      );
    }

    this.openEmailAddressDialog();
    client.on("sign", (data) => {
      this.saveDocumentSignnture(data?.signatureId);
    });
  }

  handleAdditionNotes = (event) => {
    this.setState({
      onBoadringSurveyChecklistAnswer: {
        ...this.state.onBoadringSurveyChecklistAnswer,
        [`checkListId:${this.state.selectedCheckListId}_section:${this.state.currentSection?.id}_question:${this.state.currentQuestion?.id}`]: {
          ...this.state.onBoadringSurveyChecklistAnswer[
          `checkListId:${this.state.selectedCheckListId}_section:${this.state.currentSection?.id}_question:${this.state.currentQuestion?.id}`
          ],
          additional_notes: event.target.value,
        },
      },
    });
  };
  handleQuestionAns = (event) => {
    this.setState({
      onBoadringSurveyChecklistAnswer: {
        ...this.state.onBoadringSurveyChecklistAnswer,
        [`checkListId:${this.state.selectedCheckListId}_section:${this.state.currentSection?.id}_question:${this.state.currentQuestion?.id}`]: {
          ...this.state.onBoadringSurveyChecklistAnswer[
          `checkListId:${this.state.selectedCheckListId}_section:${this.state.currentSection?.id}_question:${this.state.currentQuestion?.id}`
          ],
          answer: event.target.value,
        },
      },
    });
  };
  handleQuestionAnsForCheckBox = (option, event) => {
    const temp =
      this.state.onBoadringSurveyChecklistAnswer[
        `checkListId:${this.state.selectedCheckListId}_section:${this.state.currentSection?.id}_question:${this.state.currentQuestion?.id}`
      ]?.answer || [];
    let answer = [...temp];
    const index = answer.findIndex((ans) => {
      return ans == option;
    });
    if (index > -1) {
      answer = answer.filter((ans) => {
        return ans !== option;
      });
    } else {
      answer.push(option);
    }
    this.setState({
      onBoadringSurveyChecklistAnswer: {
        ...this.state.onBoadringSurveyChecklistAnswer,
        [`checkListId:${this.state.selectedCheckListId}_section:${this.state.currentSection?.id}_question:${this.state.currentQuestion?.id}`]: {
          ...this.state.onBoadringSurveyChecklistAnswer[
          `checkListId:${this.state.selectedCheckListId}_section:${this.state.currentSection?.id}_question:${this.state.currentQuestion?.id}`
          ],
          answer: answer,
        },
      },
    });
  };

  formatPhoneNumber = (phoneNumberString) => {
    let cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    let match = cleaned.match(/^(1)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      let intlCode = match[1] ? "+1 " : "";
      return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
    }
    return null;
  };
  setSelectedPackage = (packageName) => {
    if (this.state.roadmap_id) {
      this.setState({
        selectedPackage: packageName,
        package_management_id: packageName?.pkg?.id,
        client_choice_package_management_id: packageName?.pkg?.id,
      });
    } else {
      if (
        this.state.allow_to_change_package_id &&
        !this.state.surveyPaymentStatus
      ) {
        this.setState({
          selectedPackage: packageName,
          package_management_id: packageName?.id,
          client_choice_package_management_id: packageName?.id,
        });
      }
    }
  };
  openPotentialSavings = () => {
    this.props.openDialogBox({
      title: "",
      width: "60%",
      renderedComponent: PotentialSavings,
      withCustomDialog: false,
    });
  };
  setPackageAndMoveToNextStep = () => {

    this.setState(
      {
        currentStep: 1,
        client_choice_package_management_id: this.state.selectedPackage
          ? this.state.selectedPackage?.id
          : this.props.history.location.state?.package_management_id,
        package_management_id: this.state.selectedPackage
          ? this.state.selectedPackage?.id
          : this.props.history.location.state?.package_management_id,
      },
      () => {
        this.saveOnBoardingSurvey();
      }
    );
  };
  submitSignatureRequestAndMoveToNextStep = () => {
    let currentStep = 1;
    if (this.state?.cpaDetail?.cpa_charge_info) {
      if (this.state.surveyPaymentStatus) {
        currentStep = 3;
      } else {
        currentStep = 2;
      }
    } else {
      currentStep = 3;
    }
    this.setState(
      {
        currentStep: currentStep,
      },
      () => {
        this.checkSurveyPaymentStatus();
      }
    );
  };
  startOnBoardingSurveyForCheckList = () => {
    this.setState(
      {
        selectedCheckListId:
          this.state.availableCheckList.length > 0 &&
          this.state.availableCheckList[0]?.id,
      },
      () => {
        if (this.state.selectedCheckListId) {
          this.setState(
            {
              currentStep: 4,
            },
            () => {
              this.getCurrentSection();
            }
          );
        }
      }
    );
  };
  expandCheckList = (chklist) => {
    this.setState(
      {
        selectedCheckListId: chklist?.id,
      },
      () => {
        const sectionIndex = 0;
        const availableCheckList = [...this.state.availableCheckList];
        const index = availableCheckList?.findIndex((chkList) => {
          return chkList?.id === this.state.selectedCheckListId;
        });
        const checklist = availableCheckList[index];
        const section = checklist?.attributes?.sections?.data[sectionIndex];
        this.setState(
          (prevState) => {
            return {
              checkListStepperIndex: {
                ...prevState.checkListStepperIndex,
                [this.state.selectedCheckListId]: sectionIndex,
              },
            };
          },
          () => {
            this.setState(
              {
                section_question_index: 0,
                currentSection: section,
              },
              () => {
                const currentSection = { ...this.state.currentSection };
                const questions = currentSection?.attributes?.questions?.data;
                this.setState({
                  currentQuestion:
                    questions.length > 0
                      ? questions[this.state.section_question_index]
                      : null,
                });
              }
            );
          }
        );
      }
    );
  };
  fileInputClicked() {
    this.fileInputRef?.current?.click();
  }
  filesSelected() {
    if (this.fileInputRef?.current?.files?.length) {
      this.handleFiles(this.fileInputRef?.current?.files);
    }
  }

  handleFiles(files: FileList) {
    this.handleChecklistfiles(files);
  }

  toBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  openViewDocumentTemplateDialog = (documentItem, status, survey_id, document_template_id) => {
    this.props
      .openDialogBox({
        dataToPass: {
          documentItem: documentItem,
          btnshow: true,
          onCheckboxSubmit: this.handleCheckboxSubmit,
          checks: status,
          survey_id: survey_id,
          document_template_id: document_template_id,
        },
        width: "100%",
        renderedComponent: ViewDocumentDialogWeb,
        withCustomDialog: false,
      })
  };

 isEmailMatch = (email1, email2) => {
    if (String(email1).trim().toLowerCase() === String(email2).trim().toLowerCase()) {
      return true
    } else {
      return false
    }
  }


  openEmailAddressDialog = () => {
    this.props
      .openDialogBox({
        catchOnCancel: false,
        width: "60%",
        renderedComponent: ConfirmEmailDialog,
        withCustomDialog: true,
        dataTopass: {
          email: this.state.clientmail,
          checked: true
        },
        disableBackdropClick: true,
        disableEscapeKeyDown: true,
      })
      .then(async (value: any) => {
        if (isEmailMatch(value.email, this.state.clientmail)) {
          this.props.showToast({
            message: "Authorization Successful",
            type: "success",
          });
        }
        else {
          this.props.showToast({
            message: "authorization denied",
            type: "error",
          });
          this.openEmailAddressDialog()
        }
      });
  };


  handleCheckboxSubmit = (checked, sign, survey_id, document_template_id) => {

    if (checked) {
      this.getsignDocs(sign, survey_id, document_template_id)
      this.props.showToast({
        message: "Sign Successful",
        type: "success",
      })
    }
    else {
      this.props.showToast({
        message: "Document not Signed",
        type: "error",
      })
    }
  };


  handleChecklistfiles = async (files: FileList) => {
    const filePathsPromises = [];
    [...files].forEach((file) => {
      filePathsPromises.push(this.toBase64(file));
    });
    const filePaths = await Promise.all(filePathsPromises);
    this.setState({
      onBoadringSurveyChecklistAnswer: {
        ...this.state.onBoadringSurveyChecklistAnswer,
        [`checkListId:${this.state.selectedCheckListId}_section:${this.state.currentSection?.id}_question:${this.state.currentQuestion?.id}`]: {
          ...this.state.onBoadringSurveyChecklistAnswer[
          `checkListId:${this.state.selectedCheckListId}_section:${this.state.currentSection?.id}_question:${this.state.currentQuestion?.id}`
          ],
          file_base64: filePaths[0],
          actualfile: files[0],
        },
      },
    });
  };

  removeChecklistfiles = () => {
    this.setState({
      onBoadringSurveyChecklistAnswer: {
        ...this.state.onBoadringSurveyChecklistAnswer,
        [`checkListId:${this.state.selectedCheckListId}_section:${this.state.currentSection?.id}_question:${this.state.currentQuestion?.id}`]: {
          ...this.state.onBoadringSurveyChecklistAnswer[
          `checkListId:${this.state.selectedCheckListId}_section:${this.state.currentSection?.id}_question:${this.state.currentQuestion?.id}`
          ],
          file_base64: null,
          actualfile: null,
          document: null,
        },
      },
    });
  };
  renderInputFieldByType = (question, classes) => {
    const question_type = question?.question_type || "textfield";
    let element = (
      <TextField
        placeholder="Your Answer"
        id="questionanswer"
        name={`checkListId:${this.state.selectedCheckListId}_section:${this.state.currentSection?.id}_question:${this.state.currentQuestion?.id}.answer`}
        value={
          this.state.onBoadringSurveyChecklistAnswer[
            `checkListId:${this.state.selectedCheckListId}_section:${this.state.currentSection?.id}_question:${this.state.currentQuestion?.id}`
          ]?.answer || ""
        }
        onChange={this.handleQuestionAns}
        required
        maxRows={4}
        InputProps={{
          classes: {
            input: classes?.input,
            root: classes?.inputfieledroot,
          },
          notched: false,
        }}
        style={{ background: "#fff" }}
        minRows={4}
        fullWidth
        variant="outlined"
        color="primary"
        multiline
      ></TextField>
    );
    switch (question_type) {
      case "textfield":
        element = (
          <TextField
            placeholder="Your Answer"
            id="questionanswer"
            name={`checkListId:${this.state.selectedCheckListId}_section:${this.state.currentSection?.id}_question:${this.state.currentQuestion?.id}.answer`}
            value={
              this.state.onBoadringSurveyChecklistAnswer[
                `checkListId:${this.state.selectedCheckListId}_section:${this.state.currentSection?.id}_question:${this.state.currentQuestion?.id}`
              ]?.answer || ""
            }
            onChange={this.handleQuestionAns}
            required
            maxRows={4}
            InputProps={{
              classes: {
                input: classes?.input,
                root: classes?.inputfieledroot,
              },
              notched: false,
            }}
            style={{ background: "#fff" }}
            minRows={4}
            fullWidth
            variant="outlined"
            color="primary"
            multiline
          ></TextField>
        );
        break;
      case "checkbox":
        element = (
          <FormControl
            classes={{ root: classes.formControlQuestion }}
            component="fieldset"
            className={classes.formControl}
          >
            <FormGroup>
              {question?.question_options?.map((option, index) => {
                return (
                  <FormControlLabel
                    key={index}
                    control={
                      <Checkbox
                        checked={
                          this.state.onBoadringSurveyChecklistAnswer[
                            `checkListId:${this.state.selectedCheckListId}_section:${this.state.currentSection?.id}_question:${this.state.currentQuestion?.id}`
                          ]?.answer?.indexOf(option) > -1 || false
                        }
                        onChange={this.handleQuestionAnsForCheckBox.bind(
                          this,
                          option
                        )}
                        name={`checkListId:${this.state.selectedCheckListId}_section:${this.state.currentSection?.id}_question:${this.state.currentQuestion?.id}_checkboxoption${option}.answer`}
                      />
                    }
                    label={option}
                  />
                );
              })}
            </FormGroup>
          </FormControl>
        );
        break;
      case "radio":
        element = (
          <FormControl
            classes={{ root: classes.formControlQuestion }}
            component="fieldset"
          >
            <RadioGroup
              aria-label="gender"
              color="primary"
              name={`checkListId:${this.state.selectedCheckListId}_section:${this.state.currentSection?.id}_question:${this.state.currentQuestion?.id}.answer`}
              value={
                this.state.onBoadringSurveyChecklistAnswer[
                  `checkListId:${this.state.selectedCheckListId}_section:${this.state.currentSection?.id}_question:${this.state.currentQuestion?.id}`
                ]?.answer || ""
              }
              onChange={this.handleQuestionAns}
            >
              {question?.question_options?.map((option, index) => {
                return (
                  <FormControlLabel
                    key={index}
                    value={option}
                    control={<Radio color="primary" />}
                    label={option}
                  />
                );
              })}
            </RadioGroup>
          </FormControl>
        );
        break;
      case "multiselect":
        element = (
          <FormControl className={classes.formControl}>
            <TextField
              select
              fullWidth
              labelId="demo-mutiple-name-label"
              id="demo-mutiple-name"
              name={`checkListId:${this.state.selectedCheckListId}_section:${this.state.currentSection?.id}_question:${this.state.currentQuestion?.id}.answer`}
              value={
                this.state.onBoadringSurveyChecklistAnswer[
                  `checkListId:${this.state.selectedCheckListId}_section:${this.state.currentSection?.id}_question:${this.state.currentQuestion?.id}`
                ]?.answer || ""
              }
              variant="outlined"
              color="primary"
              onChange={this.handleQuestionAns}
            // MenuProps={{
            //   PaperProps: {
            //     style: {
            //       maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            //       width: 250,
            //     },
            //   },
            // }}
            >
              {question?.question_options?.map((name, index) => (
                <MenuItem key={name} value={name}>
                  {name}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
        );
        break;
    }
    return element;
  };
  renderFooter = (classes) => {
    return (
      <AppBar
        position="fixed"
        color="default"
        elevation={1}
        className={classes.bottomAppBar}
      >
        <Toolbar className={classes.bottomToolbar}>
          <Grid
            container
            alignItems="center"
            style={{ padding: "16px", borderTop: "1px solid #dfdfdf" }}
          >
            <Grid item xs={8}>
              <Typography
                color="textSecondary"
                className={classes.contactDetailsFooter}
              >
                {this.state.cpaDetail?.theme?.web_url}
              </Typography>
              <Typography
                color="textSecondary"
                className={classes.contactDetailsFooter}
              >
                {this.state.cpaDetail?.theme?.email}
              </Typography>
              <Typography
                color="textSecondary"
                className={classes.contactDetailsFooter}
              >
                {this.formatPhoneNumber(
                  this.state.cpaDetail?.theme?.phone_number
                )}
              </Typography>
            </Grid>
            <Grid item xs={4} style={{ flex: 1, textAlign: "end" }}>
              <Typography
                color="textSecondary"
                display="inline"
                className={classes.poweredByText}
              >
                Powered by
                <a
                  as={"a"}
                  href="https://smartpath.co/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.poweredBySmartPath}
                >
                  smartpath.co
                </a>
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    );
  };
  get4thStepContent = (currentChecklist, classes) => {
    return (
      <Grid
        container
        alignItems="flex-start"
        style={{ margin: "2rem auto", width: "96%" }}
        justifyContent="center"
        spacing={2}
        className={classes.mainCheckListContainer}
      >
        <Grid item xs={2}>
          <Grid container>
            <Grid xs={12} style={{ marginBottom: "1rem" }} item>
              <Typography
                style={{ lineHeight: "54px" }}
                color="primary"
                variant="h2"
              >
                {this.state.selectedPackage?.attributes?.name}
              </Typography>
              <CompletetionPercentageSlider
                style={{ padding: 0, paddingBottom: "8px" }}
                value={this.state.completeStatus}
                valueLabelFormat={(x) => {
                  return x + "%";
                }}
                valueLabelDisplay="on"
                onChange={() => { }}
                aria-labelledby="continuous-slider"
              />
              <div style={{ display: "flex", gap: "8px" }}>
                <Typography color="textPrimary" variant="body1">
                  Questions?
                </Typography>
                <Typography
                  className={classes.underline}
                  color="primary"
                  variant="body1"
                >
                  Contact us
                </Typography>
              </div>
            </Grid>
            {this.state.availableCheckList
              ?.map((checkList, index) => {
                return {
                  ...checkList,
                  isExpanded: checkList?.id == this.state.selectedCheckListId,
                };
              })
              .map((checklist) => {
                return (
                  <Grid
                    className={classes.packageName}
                    xs={12}
                    key={checklist?.id}
                    item
                  >
                    <div
                      className={classes.expandicon}
                      onClick={() => {
                        this.expandCheckList(checklist);
                      }}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                    >
                      <IconButton style={{ padding: 0 }}>
                        {checklist?.isExpanded ? (
                          <ExpandMoreIcon color="primary" />
                        ) : (
                          <ChevronRightIcon color="primary" />
                        )}
                      </IconButton>
                      <Typography color="primary" variant="h2">
                        {checklist?.attributes?.checklist_name}
                      </Typography>
                    </div>
                    {checklist?.isExpanded && (
                      <div>
                        <Stepper
                          activeStep={
                            this.state.checkListStepperIndex[checklist?.id]
                          }
                          classes={{ root: classes.stepperRoot }}
                          connector={<ColorlibConnector />}
                          orientation="vertical"
                        >
                          {checklist?.attributes?.sections?.data.map(
                            (label, index) => (
                              <ColorStep
                                onClick={() => {
                                  if (
                                    index <
                                    this.state.checkListStepperIndex[
                                    checklist?.id
                                    ]
                                  ) {
                                    this.goToPriviousSection(index);
                                  }
                                }}
                                key={label?.id}
                              >
                                <ColorStepLabel
                                  StepIconComponent={ColorlibStepIcon}
                                >
                                  {label?.attributes?.section_name}
                                </ColorStepLabel>
                              </ColorStep>
                            )
                          )}
                        </Stepper>
                      </div>
                    )}
                  </Grid>
                );
              })}
          </Grid>
        </Grid>
        <Grid item xs={7}>
          <Grid container>
            <Grid item xs={10} className={classes.checkListContainer}>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <Typography
                    style={{ fontSize: "2rem" }}
                    color="primary"
                    variant="h1"
                  >
                    {currentChecklist?.attributes.checklist_name} -{" "}
                    {this.state?.currentSection?.attributes?.section_name}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid
                    container
                    spacing={3}
                    className={classes.checkListQuestionContainer}
                  >
                    <Grid item xs={12}>
                      <Typography
                        color="textPrimary"
                        style={{
                          fontSize: "1.125rem",
                          marginBottom: "0.5rem",
                        }}
                        display="inline"
                      >
                        {
                          this.state.onBoadringSurveyChecklistAnswer[
                            `checkListId:${this.state.selectedCheckListId}_section:${this.state.currentSection?.id}_question:${this.state.currentQuestion?.id}`
                          ]?.count
                        }
                        .
                      </Typography>
                      &nbsp;
                      <Typography
                        color="textPrimary"
                        style={{
                          fontSize: "1.125rem",
                          marginBottom: "0.5rem",
                        }}
                        component={"div"}
                        display="inline"
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(
                            this.state.currentQuestion?.attributes
                              ?.question_name,
                            { ADD_ATTR: ["target"] }
                          ),
                        }}
                      />
                      {this.renderInputFieldByType(
                        this.state.currentQuestion?.attributes,
                        classes
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="additionalNotes"
                        placeholder="Additional Notes"
                        maxRows={5}
                        name={`checkListId:${this.state.selectedCheckListId}_section:${this.state.currentSection?.id}_question:${this.state.currentQuestion?.id}.additional_notes`}
                        value={
                          this.state.onBoadringSurveyChecklistAnswer[
                            `checkListId:${this.state.selectedCheckListId}_section:${this.state.currentSection?.id}_question:${this.state.currentQuestion?.id}`
                          ]?.additional_notes || ""
                        }
                        onChange={this.handleAdditionNotes}
                        InputProps={{
                          classes: {
                            input: classes?.input,
                            root: classes?.inputfieledroot,
                          },
                          notched: false,
                        }}
                        style={{ background: "#fff" }}
                        minRows={5}
                        fullWidth
                        variant="outlined"
                        color="primary"
                        multiline
                      ></TextField>
                    </Grid>
                    {this.state.currentQuestion?.attributes
                      ?.request_document && (
                        <Grid item xs={12}>
                          <>
                            <Box className={classes.uploadboxOutline}>
                              <div style={{ flex: 1, display: "flex" }}>
                                {this.state.onBoadringSurveyChecklistAnswer[
                                  `checkListId:${this.state.selectedCheckListId}_section:${this.state.currentSection?.id}_question:${this.state.currentQuestion?.id}`
                                ]?.actualfile?.name ||
                                  this.state.onBoadringSurveyChecklistAnswer[
                                    `checkListId:${this.state.selectedCheckListId}_section:${this.state.currentSection?.id}_question:${this.state.currentQuestion?.id}`
                                  ]?.document ? (
                                  <>
                                    <Typography
                                      color="primary"
                                      className={classes.uploadedFileName}
                                    >
                                      {this.state.onBoadringSurveyChecklistAnswer[
                                        `checkListId:${this.state.selectedCheckListId}_section:${this.state.currentSection?.id}_question:${this.state.currentQuestion?.id}`
                                      ]?.actualfile?.name ||
                                        this.state
                                          .onBoadringSurveyChecklistAnswer[
                                          `checkListId:${this.state.selectedCheckListId}_section:${this.state.currentSection?.id}_question:${this.state.currentQuestion?.id}`
                                        ]?.document}
                                    </Typography>
                                    <IconButton
                                      id="removeUploadedFile"
                                      onClick={() => {
                                        this.removeChecklistfiles();
                                      }}
                                    >
                                      <CloseOutlined></CloseOutlined>
                                    </IconButton>
                                  </>
                                ) : (
                                  <Typography
                                    className={classes.uploadedFileName}
                                    color="textSecondary"
                                  >
                                    Select file to upload
                                  </Typography>
                                )}
                              </div>
                              {!this.state.onBoadringSurveyChecklistAnswer[
                                `checkListId:${this.state.selectedCheckListId}_section:${this.state.currentSection?.id}_question:${this.state.currentQuestion?.id}`
                              ]?.document && (
                                  <IconButton
                                    className={classes.fileuploadicon}
                                    onClick={this.fileInputClicked.bind(this)}
                                  >
                                    <InsertDriveFileOutlinedIcon></InsertDriveFileOutlinedIcon>
                                  </IconButton>
                                )}
                            </Box>
                            <input
                              ref={this.fileInputRef}
                              name={`checkListId:${this.state.selectedCheckListId}_section:${this.state.currentSection?.id}_question:${this.state.currentQuestion?.id}.file`}
                              className={classes.fileInput}
                              type="file"
                              accept={
                                "application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                              }
                              multiple={false}
                              onChange={this.filesSelected.bind(this)}
                            />
                          </>
                        </Grid>
                      )}
                  </Grid>
                </Grid>
                <Grid item xs={11}>
                  <Grid container spacing={2} justifyContent="flex-end">
                    <Button
                      id="saveforlater"
                      onClick={this.saveForLater}
                      variant="outlined"
                      color="primary"
                      style={{ marginRight: "24px" }}
                    >
                      Save for Later
                    </Button>
                    <Button
                      id="nextbutton"
                      disabled={
                        !this.state.onBoadringSurveyChecklistAnswer[
                          `checkListId:${this.state.selectedCheckListId}_section:${this.state.currentSection?.id}_question:${this.state.currentQuestion?.id}`
                        ]?.answer
                      }
                      onClick={this.updateCheckList}
                      variant="contained"
                      color="primary"
                    >
                      Next
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  render() {
    const { classes } = this.props;
    const availableCheckList = this.state.availableCheckList;
    const index = availableCheckList?.findIndex((chkList) => {
      return chkList?.attributes?.id == this.state.selectedCheckListId;
    });
    const currentChecklist = availableCheckList[index];

    return (
      <ThemeProvider theme={createTheme(this.state.theme)}>
        <div style={{ position: "relative" }}>
          <AppBar
            position="sticky"
            color="default"
            elevation={1}
            className={classes.appBar}
          >
            <Toolbar className={classes.toolbar}>
              <Box className={classes.toolbarLogo}>
                {this.state.cpaDetail?.theme?.logo ? (
                  <img
                    src={this.state.cpaDetail?.theme?.logo}
                    alt="logo"
                    style={{
                      height: '90%',
                      borderRadius: '0.25rem',
                      marginRight: 'auto',
                      position: 'absolute',
                      left: '1%',
                      top: '4px'
                    }}
                  />
                ) : (
                  <Typography>
                    {this.state.cpaDetail?.theme?.firm_name}
                  </Typography>
                )}
              </Box>
            </Toolbar>
          </AppBar>
          {this.state.isLoadingPage && (
            <Grid
              container
              className={classes.mainContainer}
              justifyContent="center"
              alignItems="center"
            >
              <Grid xs={8} item>
                <LinearProgress
                  style={{ borderRadius: "24px" }}
                  color="primary"
                />
              </Grid>
            </Grid>
          )}

          {this.state.currentStep === 0 && (
            <PackageList
              openPotentialSavings={this.openPotentialSavings.bind(this)}
              classes={classes}
              packageList={this.state.packageList}
              roadmap_id={this.state.roadmap_id}
              selectedPackage={
                this.state.selectedPackage
                  ? this.state.selectedPackage
                  : {
                    id: this.props.history.location.state
                      ?.package_management_id,
                  }
              }
              setSelectedPackage={this.setSelectedPackage.bind(this)}
              setPackageAndMoveToNextStep={this.setPackageAndMoveToNextStep.bind(
                this
              )}
              {...this.props}
            />
          )}
          {this.state.currentStep === 1 && (
            <DocumentList
              signatureRequests={this.state.signatureRequests}
              submitSignatureRequestAndMoveToNextStep={this.submitSignatureRequestAndMoveToNextStep.bind(
                this
              )}
              openViewDocumentTemplateDialog={this.openViewDocumentTemplateDialog.bind(this)}
              classes={classes}
              {...this.props}
            />
          )}
          {this.state.currentStep === 2 && (
            <PaymentPage
              cpaDetail={this.state.cpaDetail}
              roadmap_id={this.state.roadmap_id}
              payment_term={this.state.payment_term}
              collectPayment={this.collectPayment.bind(this)}
              selectedPackage={this.state.selectedPackage}
              packageList={this.state.packageList}
              openPotentialSavings={this.openPotentialSavings.bind(this)}
              package_management_id={this.state.package_management_id}
              {...this.props}
            />
          )}
          {this.state.currentStep === 3 && (
            <CheckListContainer
              roadmap_id={this.state.roadmap_id}
              availableCheckList={this.state.availableCheckList}
              selectedPackage={this.state.selectedPackage}
              startOnBoardingSurveyForCheckList={this.startOnBoardingSurveyForCheckList.bind(
                this
              )}
              saveForLater={this.saveForLater.bind(this)}
              {...this.props}
            />
          )}
          {this.state.currentStep === 4 &&
            this.get4thStepContent(currentChecklist, classes)}
          {this.state.currentStep === 5 && (
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              className={classes.mainContainer}
            >
              <Grid item md={6} xs={12}>
                <Grid container justifyContent="center" alignItems="center">
                  <Grid item xs={8}>
                    <Typography className={classes.successheading}>
                      Success!
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                      color="textSecondary"
                      className={classes.successSubHeading}
                    >
                      Your enrollment has been received.
                      <br />
                      We will contact you with next steps
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
          {this.renderFooter(classes)}
        </div>
      </ThemeProvider>
    );
  }
}
const OnBoardingSurveyLandingPageWithRouter = withRouter(
  OnBoardingSurveyLandingPage
);
const OnBoardingSurveyLandingPageWithToast = withToast(
  OnBoardingSurveyLandingPageWithRouter
);
const OnBoardingSurveyLandingPageWithLoader = withLoader(
  OnBoardingSurveyLandingPageWithToast
);
const OnBoardingSurveyLandingPageWithAlertBox = withAlertBox(
  OnBoardingSurveyLandingPageWithLoader
);
const OnBoardingSurveyLandingPageWithDialogBox = withDialog(
  OnBoardingSurveyLandingPageWithAlertBox
);

export default withStyles((theme) =>
  createStyles({
    logoContainer: {
      textAlign: "center",
      marginBottom: "1rem",
    },
    logoImage: {
      width: "30%",
      height: "auto",
    },
    heading: {
      fontFamily: "Nunito Sans",
      fontSize: "2.8rem",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "normal",
      textAlign: "center",
      fontWeight: 700,
      borderBottom: "1px #D3D3D3 solid",
      cursor: "default",
    },
    successheading: {
      fontFamily: "Nunito Sans",
      fontSize: "2.8rem",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.33,
      letterSpacing: "normal",
      textAlign: "center",
      color: localStorage.getItem("themePrimaryColor") || "#54A4A6",
      fontWeight: 700,
      borderBottom: "1px #D3D3D3 solid",
    },
    subHeading: {
      fontFamily: "Nunito Sans",
      fontSize: "1.3rem",
      fontStretch: "normal",
      fontStyle: "normal",
      letterSpacing: "normal",
      textAlign: "center",
      color: theme.palette.text.secondary,
      fontWeight: 500,
      marginBottom: "4rem",
    },
    successSubHeading: {
      fontFamily: "Nunito Sans",
      fontSize: "1.3rem",
      fontStretch: "normal",
      fontStyle: "normal",
      letterSpacing: "normal",
      textAlign: "center",
      color: theme.palette.text.secondary,
      fontWeight: 500,
      margin: "2rem 0",
    },
    packageNameCard: {
      border: `1px solid white`,
      borderRadius: "0.875rem",
      backgroundColor: "#ffffff",
      boxShadow: "0 2px 8px 0 rgba(0, 0, 0, 0.29)",
      padding: "2rem 3rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginRight: "3%",
      fontSize: "1rem",
      color: theme.palette.text.secondary,
      fontWeight: 600,
      marginBottom: "1rem",
      [theme.breakpoints.down("sm")]: {
        flexGrow: 0,
        maxWidth: "100%",
        flexBasis: "100%",
        marginRight: 0,
      },
    },
    appBar: {
      width: "100%",
      zIndex: theme.zIndex.drawer + 1,
      boxShadow: "none",
      backgroundColor: " #ffffff",
    },
    toolbar: {
      flexWrap: "nowrap",
      justifyContent: "center",
    },
    toolbarLogo: {
      flex: "0 0 12%",
      position: "absolute",
      top: 10,
      left: 10,
      height: "50px",
      width: "50px",
    },
    mainContainer: {
      height: "calc(100vh - 30vh)",
      maxHeight: "calc(100vh - 30vh)",
      overflowY: "auto",
      "&::-webkit-scrollbar": {
        width: "2px",
        backgroundColor: "#F5F5F5",
      },
      "&::-webkit-scrollbar-track": {
        // boxShadow: `inset 0 0 6px rgba(0, 0, 0, 0.3)`,
        backgroundColor: "#fff",
        borderRadius: "8px",
      },
      "&::-webkit-scrollbar-thumb": {
        background: "#C7C7C8",
        borderRadius: "8px",
      },
    },
    mainCheckListContainer: {
      height: "calc(100vh - 31vh)",
      maxHeight: "calc(100vh - 31vh)",
      overflowY: "auto",
      "&::-webkit-scrollbar": {
        width: "2px",
        backgroundColor: "#F5F5F5",
      },
      "&::-webkit-scrollbar-track": {
        // boxShadow: `inset 0 0 6px rgba(0, 0, 0, 0.3)`,
        backgroundColor: "#fff",
        borderRadius: "8px",
      },
      "&::-webkit-scrollbar-thumb": {
        background: "#C7C7C8",
        borderRadius: "8px",
      },
    },
    saveForLatter: {
      background: "#8F93A2",
      "&:hover": {
        background: "#8F93A2",
      },
    },
    bottomAppBar: {
      top: "auto",
      bottom: 0,
      width: "100%",
      zIndex: theme.zIndex.drawer + 1,
      backgroundColor: " #ffffff",
    },
    bottomToolbar: {
      flexWrap: "nowrap",
      minHeight: "50px",
    },
    contactDetailsFooter: {
      display: "inline-block",
      fontFamily: "Nunito Sans",
      fontSize: "1.2rem",
      marginRight: "3rem",
      fontWeight: 600,
    },
    poweredByText: {
      display: "inline-block",
      fontFamily: "Nunito Sans",
      fontSize: "1.1rem",
      marginRight: "3rem",
      fontWeight: 600,
      opacity: 0.6,
    },
    poweredBySmartPath: {
      display: "inline-block",
      fontFamily: "Nunito Sans",
      fontSize: "1.1rem",
      marginRight: "3rem",
      fontWeight: 600,
      paddingLeft: "0.3rem",
      cursor: "pointer",
      textDecoration: "none",
      color: theme.palette.text.secondary,
    },

    packagePageHeading: {
      fontSize: "1.8rem",
      fontWeight: 700,
      marginTop: "2rem",
    },
    packagePageSubHeading: {
      fontSize: "16px",
      fontWeight: 600,
    },

    cardPackageObs: {
      minHeight: "18rem",
      borderRadius: "1.8rem",
      backgroundColor: "#ffffff",
      boxShadow: "0px 0px 6px 2px rgba(0, 0, 0, 0.1)",
      // border : '3px solid #fcfcfc',
      border: `2px solid white`,
      padding: "1rem",
      margin: "3% 1%",
      display: "flex",
      justifyContent: "center",
      cursor: "pointer",
      flexGrow: 0,
      maxWidth: "27%",
      flexBasis: "27%",
      [theme.breakpoints.down("sm")]: {
        flexGrow: 0,
        maxWidth: "100%",
        flexBasis: "100%",
        marginRight: 0,
      },
    },

    cardPackageSelectedObs: {
      border: `2px solid ${theme.palette.primary.main}`,
      position: "relative",
      minHeight: "18rem",
      borderRadius: "1.8rem",
      backgroundColor: "#ffffff",
      boxShadow: "0px 0px 12px 0px #54A4A6",
      padding: "1rem",
      margin: "3% 1%",
      display: "flex",
      justifyContent: "center",
      cursor: "pointer",
      flexGrow: 0,
      maxWidth: "27%",
      flexBasis: "27%",
      [theme.breakpoints.down("sm")]: {
        flexGrow: 0,
        maxWidth: "100%",
        flexBasis: "100%",
        marginRight: 0,
      },
      // "&::after": {
      //   position: "absolute",
      //   content: "''",
      //   background: `url(${checkMarkPhaseTwo}) no-repeat center center`,
      //   backgroundSize: "cover",
      //   right: "3%",
      //   top: "3%",
      //   height: "1.6875rem",
      //   width: "1.6875rem",
      // },
    },
    optionTitle: {
      fontSize: "1.3rem",
      fontWeight: 700,
    },
    optionPriceLarge: {
      fontSize: "1.8rem",
      fontWeight: 700,
      marginBottom: "0.5rem",
    },
    optionPriceSmall: {
      fontSize: "1rem",
      fontWeight: 600,
      marginBottom: "0.5rem",
      marginLeft: "0.25rem",
    },
    featureText2: {
      marginBottom: "0.5rem",
      fontSize: "1.1rem",
      // fontWeight: 700,
      position: "relative",
      paddingLeft: "1.25rem",
      margin: "0.6rem",
      "&::before": {
        borderRadius: "50%",
        backgroundColor: theme.palette.primary.main,
        position: "absolute",
        left: 0,
        width: "0.625rem",
        top: "0.4rem",
        height: "0.625rem",
      },
    },

    featureListContainer: {
      maxHeight: "18rem",
      overflowY: "auto",
      "&::-webkit-scrollbar": {
        width: "2px",
        backgroundColor: "#F5F5F5",
      },
      "&::-webkit-scrollbar-track": {
        // boxShadow: `inset 0 0 6px rgba(0, 0, 0, 0.3)`,
        backgroundColor: "#fff",
        borderRadius: "8px",
      },
      "&::-webkit-scrollbar-thumb": {
        background: "#C7C7C8",
        borderRadius: "8px",
      },
    },
    documentListContainer: {
      minHeight: "30rem",
      borderRadius: "1.8rem",
      backgroundColor: "#ffffff",
      boxShadow: "0px 0px 6px 2px rgba(0, 0, 0, 0.1)",
      // border : '3px solid #fcfcfc',
      border: `2px solid white`,
      padding: "1rem",
      margin: "3% auto",
      flexGrow: 1,
      maxWidth: "88%",
      flexBasis: "88%",
      [theme.breakpoints.down("sm")]: {
        flexGrow: 0,
        maxWidth: "100%",
        flexBasis: "100%",
        marginRight: 0,
      },
    },
    docListItem: {
      background: "#f8f8f8",
      margin: "8px 0",
      borderRadius: "10px",
      padding: "8px",
      cursor: 'pointer'
    },
    docListText: {
      color: theme.palette.text.secondary,
      fontSize: "1.5rem",
    },
    listContainer: {
      maxHeight: "27rem",
      overflow: "auto",
      "&::-webkit-scrollbar": {
        width: "6px",
        backgroundColor: "#F5F5F5",
      },
      "&::-webkit-scrollbar-track": {
        // boxShadow: `inset 0 0 6px rgba(0, 0, 0, 0.3)`,
        backgroundColor: "#fff",
        borderRadius: "8px",
      },
      "&::-webkit-scrollbar-thumb": {
        background: "#C7C7C8",
        borderRadius: "8px",
      },
    },
    documentOpenLink: {
      textDecoration: "underline",
      cursor: 'pointer'
    },
    checkListContainer: {
      minHeight: "40rem",
      borderRadius: ".875rem",
      backgroundColor: "#ffffff",
      boxShadow: "0px 0px 6px 2px rgba(0, 0, 0, 0.1)",
      padding: "48px",
      border: `2px solid white`,
      flexGrow: 1,
      maxWidth: "100%",
      flexBasis: "100%",
      [theme.breakpoints.down("sm")]: {
        flexGrow: 0,
        maxWidth: "100%",
        flexBasis: "100%",
        marginRight: 0,
      },
    },
    packageName: {
      border: `1px solid ${theme.palette.primary.main}`,
      width: "100%",
      borderRadius: "0.875rem",
      backgroundColor: "#ffffff",
      boxShadow: "0 2px 8px 0 rgba(0, 0, 0, 0.29)",
      padding: "1rem 2rem",
      // cursor: "pointer",
      marginRight: "3%",
      fontSize: "1rem",
      color: theme.palette.primary.main,
      fontWeight: 600,
      marginBottom: "1rem",
    },
    stepperRoot: {
      padding: "0",
    },
    checkListQuestionContainer: {
      minHeight: "40vh",
      borderRadius: ".875rem",
      backgroundColor: "#F8F8F8",
      padding: "32px",
      flexGrow: 1,
      maxWidth: "100%",
      flexBasis: "100%",
      [theme.breakpoints.down("sm")]: {
        flexGrow: 0,
        maxWidth: "100%",
        flexBasis: "100%",
        marginRight: 0,
      },
    },
    inputfieledroot: {
      background: "#fff",
      border: "none",
    },
    input: {
      background: "#fff",
      border: "none",
    },
    noItemAvailable: {
      textAlign: "center",
      lineHeight: "30vh",
      fontSize: "1.25rem",
      fontWeight: "500",
      color: theme.palette.text.secondary,
    },
    fileInput: {
      display: "none",
    },
    uploadboxOutline: {
      background: "#fff",
      display: "flex",
      alignItems: "center",
      width: "100%",
      height: "40px",
      borderRadius: "10px",
    },
    uploadedFileName: {
      fontSize: "1rem",
      lineHeight: "40px",
      padding: "0 16px",
    },
    formControl: {
      margin: theme.spacing(3),
    },
    formControlQuestion: {
      padding: "8px 16px",
      borderRadius: "10px",
      width: "100%",
      background: "#f3f3f3",
      margin: 0,
    },
    underline: {
      textDecoration: "underline",
    },
    cardPackageBottomTextpt: {
      fontSize: "1.1rem",
      fontWeight: 600,
      paddingTop: "1rem",
      borderTop: "1px solid rgba(0, 0, 0, 0.08)",
    },

    featureTextOBS: {
      fontSize: "1.1rem",
      // fontWeight: 700,
      marginBottom: "0.5rem",
      margin: "0.6rem",
      paddingLeft: "1.25rem",
      position: "relative",
      "&::before": {
        position: "absolute",
        content: "''",
        backgroundColor: theme.palette.primary.main,
        borderRadius: "50%",
        left: 0,
        top: "0.4rem",
        height: "0.625rem",
        width: "0.625rem",
      },
    },
  })
)(OnBoardingSurveyLandingPageWithDialogBox);
// Customizable Area End